import React from "react"
import PropTypes from "prop-types"

// Components
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import ArchiveStyles from "./archives.module.css"
import SEO from "../components/seo";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <SEO title={`Tagged with ${tag}`}></SEO>
      <h1>{tagHeader}</h1>
      <Link to="/tags" className={ArchiveStyles.allTagsLink}>All tags</Link>
      <ul className={ArchiveStyles.postList}>
        {edges.map(({ node }) => {
          const { slug } = node.fields
          const { title, date, categories } = node.frontmatter
          const featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid
          return (
            <li>
              <article>
                <Link to={slug}><Img className={ArchiveStyles.image} fluid={featuredImgFluid} alt={title} /></Link>
                <span className={ArchiveStyles.meta}>{date} - <Link to={"/"} className={ArchiveStyles.cat}>{categories}</Link></span>
                <h3><Link to={slug}>{title}</Link></h3>
                <p className={ArchiveStyles.intro}>{node.excerpt}</p>
                <Link to={slug} className={ArchiveStyles.readmore}>Read more</Link>
              </article>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
              date: PropTypes.string.isRequired,
              categories: PropTypes.array.isRequired,
              featuredImage: PropTypes.element.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
            excerpt: PropTypes.string.isRequired,
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD/MM/yy")
            title
            categories
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`